import "./landing-page.scss";

import * as R from "ramda";

import { object, shape, string } from "prop-types";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb.jsx";
import Container100 from "../../components/Container/Container100.jsx";
import ContainerBleed from "../../components/Container/ContainerBleed.jsx";
import Page from "../../components/Page/Page.jsx";
import Promo3Up from "../../components/Promo3Up/Promo3Up.jsx";
import SignUp from "../../components/SignUp/SignUp.jsx";
import SubPageHero from "../../components/SubPageHero/SubPageHero.jsx";
import TwoUpTextBanner from "../../components/TwoUpTextBanner/TwoUpTextBanner.jsx";
import extractInfoBlock from "../../common/extractInfoBlock.js";
import extractRichText from "../../common/extractRichText.jsx";
import extractSubPageHero from "../../common/extractSubPageHero.js";
import extractTag from "../../common/extractTag.js";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";
import { graphql } from "gatsby";

const propTypes = {
  pageContext: shape({ id: string.isRequired }).isRequired,
  data: object,
};

const LandingPage = (props) => {
  const contentfulData = props.data;

  const {
    pageContext: { id },
  } = props;

  const pageComponents = R.compose(
    R.evolve({
      landingPageRichText: extractRichText,
      subPageHero: R.unless(R.isNil, extractSubPageHero),
      landingPageTwoUpTextBanner: R.unless(R.isNil, extractTwoUpTextBanner),
    }),
    R.path(["contentfulPage", "pageContent"]),
  )(contentfulData);

  const pageInfo = R.compose(
    R.compose(R.unless(R.isNil, extractInfoBlock)),
    R.path(["data", "contentfulPage", "pageListingInfo"]),
  )(props);

  const listingInfoItems = R.compose(
    R.map(R.compose(extractInfoBlock, R.prop("pageListingInfo"))),
    //if a page has no listing info, remove it from the array
    R.filter(R.compose((data) => data !== null, R.prop("pageListingInfo"))),
    R.defaultTo([]),
    R.path(["data", "contentfulPage", "subPages"]),
  )(props);

  const pageTitle = R.path(["data", "contentfulPage", "title"])(props);

  const tag = R.compose(
    R.unless(R.isNil, extractTag),
    R.path(["contentfulPage", "pageTag"]),
  )(contentfulData);

  const { subPageHero, landingPageRichText, landingPageTwoUpTextBanner } =
    pageComponents;

  return (
    <Page
      pageTitle={pageTitle}
      pageDescription={subPageHero && subPageHero?.subtitle}
      className="landing-page"
    >
      <BreadCrumb pageId={id} />
      {subPageHero && <SubPageHero tag={tag} {...subPageHero} />}
      {landingPageRichText && (
        <Container100>{landingPageRichText}</Container100>
      )}
      {listingInfoItems && (
        <Container100>
          <Promo3Up
            theme="dark"
            title={pageInfo && pageInfo?.title}
            subtitle={pageInfo && pageInfo.text}
            cards={listingInfoItems}
          />
        </Container100>
      )}
      {landingPageTwoUpTextBanner && (
        <ContainerBleed>
          <TwoUpTextBanner {...landingPageTwoUpTextBanner} />
        </ContainerBleed>
      )}
      <ContainerBleed>
        <SignUp
          title="Join Our Movement"
          subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system!!!! This is a test!!!"
        />
      </ContainerBleed>
    </Page>
  );
};

LandingPage.propTypes = propTypes;
export default LandingPage;

export const query = graphql`
  query LandingPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...Page
    }
  }
`;
